@import url(https://fonts.googleapis.com/css?family=Merriweather:300,400,700,900|Almarai:300,400,700,800|Alatsi|Mulish:200,300,400,500,600,700,800,900|Open+Sans:300,400,600,700,800|Noto+Sans+TC:100,300,400,500,700,900|Rosario:300,400,500,600,700|Quicksand:300,400,500,600);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9f9f9;
}

.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 20px;
}

